import React from 'react'
// import bgEmployer from '@src/assets/images/employer/Hero.svg'
// import bgMobile from '@src/assets/images/employer/HeroMobile.svg'
import { useTranslation } from 'react-i18next'
// import imagebg from '@src/assets/images/employer/imagebg.svg'
import imagebgweb from '@src/assets/images/employer/imagebgweb.png'
import { useWindowDimensions } from '@src/utility/hooks/useWindowDimensions'
import { Link } from 'react-router-dom'

const Banner = ({ }) => {
  const { width } = useWindowDimensions()
  const isMobile = width < 768
  const isTab = width >= 768 && width <= 1024
  const color1 = "#633B9E"
  const color2 = "#A87CE9"
  const { t } = useTranslation()
  return (
    <section className={`relative section-full`} style={{
      marginLeft: isMobile ? 12 : "2rem",
      marginRight: isMobile ? 12 : "2rem",
      backgroundRepeat: 'no-repeat',
      height: isMobile ? '600px' : '386px',
      marginTop: isMobile ? "80px" : '74px',
      padding: isMobile ? "20px 0px 0px 0px" : '2rem',
      marginBottom: '0',
      borderRadius: "32px",
      background: isMobile ? `linear-gradient(to bottom,  ${color1} 0%,${color2} 180%)` : `linear-gradient(to right,  ${color1} 0%,${color2} 180%)`
    }}>
      <div className={`${!isTab && "custom-container"} flex items-start justify-start`}>
        <div className={isMobile ? "flex flex-col items-start justify-start" : ""}>
          <div className={isMobile ? "bg-color-pink-200 text-white banner-pink text-small align-center w-full" : 'bg-color-pink-200 text-white banner-pink text-small'}>{t("To companies considering employing foreign workers")}</div>
          <div style={{ textAlign: isMobile ? "center" : "left" }} className={`text-white ${isMobile ? "text-32 w-full" : isTab ? "text-32 w-70" : "text-xl w-70"} font-bold`}>{t("addressingLaborShortage")}</div>
          <div style={{ textAlign: isMobile ? "center" : "left" }} className={`text-small text-white ${isMobile && "w-full"} ${isTab && "w-50"}`}>{t('Corresponding to various residency statuses such as specified skilled workers')}</div>
          <Link to="/employer/register" style={{ alignSelf: 'center' }} className='text-purple-200 mb-4 register-btn'>{t('Register Your Company')}</Link>
        </div>
        <img style={{height : isMobile ? 220 : isTab ? 240 : 380, bottom : 1, width : isMobile ? 280 : ""}} className={`absolute  ${isMobile ?  "right-12" : "bottom-0 right-10"}`} src={imagebgweb} alt='imagebg' />
      </div>
    </section>
  )
}

export default Banner