import React from 'react'
import checklist from "@src/assets/icons/check-circle.svg";
import icon1 from "@src/assets/images/work-process/register-seeker.svg"
import icon2 from '@src/assets/icons/chevron-right.svg'
import { useWindowDimensions } from '@src/utility/hooks/useWindowDimensions';
import { useGetJobStatsQuery } from '@src/redux/job/job_stats';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const StatsV2 = () => {

    const { width } = useWindowDimensions()
    const isMobile = width <= 1024
    const { data, isLoading } = useGetJobStatsQuery()
    const dataSeeker = 5000 + parseInt(data?.seekerStats || 0)

    const { t } = useTranslation()

    const streamLine = [
        {
            value: "Job posting support"
        },
        {
            value: "Document processing services"
        },
        {
            value: "Operational support services"
        },
    ]
    const navigate = useNavigate()
    return (
        <div className="section-full p-b30 twm-how-it-work-area mt-2">
            <div className={`custom-container`}>
                <div className={`stats-v2 ${isMobile ? 'flex flex-col w-full gap-y-2' : ''}`}>
                    <div className={`font-bold ${isMobile ? 'w-full text-24' : 'w-13 text-lg'}`}>{t("Hire confidently, simplify your journey to hiring your first foreign employee.")}</div>
                    <div className={`${isMobile ? 'w-full' : 'w-13'} text-md`}>
                        <div className='text-md'>
                            {t("Streamline your first-time foreign recruitment with expert assistance.")}
                        </div>
                        <div className='stream-list'>
                            {
                                streamLine.map((item, index) => (
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '8px'
                                    }} key={index}>
                                        <img src={checklist} alt='checklist' />
                                        <div className='text-md'>{t(`${item.value}`)}</div>
                                    </div>
                                )
                                )
                            }
                        </div>
                    </div>
                    <div className={`${isMobile ? 'w-full' : 'w-13'} bg-pink-500 flex items-start gap-x-4 rounded-lg p-24`}>
                        <img src={icon1} />
                        <div className={''}>
                            <div className='text-lg text-white font-semibold'>
                                {
                                    isLoading ? "Counting . . ." : `${dataSeeker} +`
                                }
                            </div>
                            {
                                !isMobile &&
                                <div>
                                    {/* <div className='text-md text-white'>{t("Registered seeker")}</div> */}
                                    <div className='text-md text-white'>{t("Registered seeker")}</div>
                                    <div className='font-thin text-white'>{t("Million daily active users")}</div>
                                </div>
                            }
                            <div onClick={() => {
                                navigate('/candidate')
                            }} className='flex items-center mt-2 gap-x-2 cursor-pointer'>
                                <div className={`${isMobile ? "text-sm" : "text-md"} text-white`}>
                                    {t("Find out more")}
                                </div>
                                <img src={icon2} alt='right-arrow' />
                            </div>
                        </div>
                        {
                            isMobile &&
                            <div className='w-13 mt-1'>
                                <div className='text-sm text-white'>{t("Registered seeker")}</div>
                                <div className='font-thin text-sm text-white'>{t("Million daily active users")}</div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StatsV2