import React, { useEffect } from "react"
import Head from "../../components/head"
import NavigationBar from "@shared/NavigationBar"
import Footer from "@shared/Footer"
import {
  footer as footerData,
  navbar as navigations
} from "@src/navigation/landing-seeker"
import banner1 from "@src/assets/images/banner/1.jpg"
import { Card, CardBody, Container, CardImg, CardTitle } from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import noPhoto from "@src/assets/images/no-photo.jpg"
import { useGetJobSeekerMutation } from "@src/redux/job/job_seeker"
import constants from "@src/utility/constants"
import { loggedInfo } from "@src/helpers/checkAuth"

import { Autoplay } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.min.css"
import { useWindowDimensions } from "@src/utility/hooks/useWindowDimensions"
import Tower from "@src/assets/icons/tower.svg"
import Location from "@src/assets/icons/location-dot.svg"
import './index.scss'
import toast from "react-hot-toast"

const Candidate = () => {
  const { role } = loggedInfo()

  const [getJobSeeker, result] = useGetJobSeekerMutation()
  const { data, isLoading, isSuccess } = result

  useEffect(() => {
    getJobSeeker()
  }, [])

  const navigate = useNavigate()

  const goDetail = (seekerId) => {
    if (role === 'seeker') return toast.error("Please login as employer to view this candidate")

    navigate(`/login`, { state: { seekerId } })
  }

  return (
    <div className="page-wrapper">
      <Head
        title={"Employee Candidate - Mahajob"}
        name={"Maha-Job"}
        description={"List of Employee Candidate"}
      />
      <NavigationBar isNew={true} navigations={navigations} page="seeker" />
      <div className="page-content bg-white">
        <div
          className="wt-bnr-inr overlay-wraper bg-center"
          style={{ backgroundImage: `url(${banner1})` }}
        >
          <div className="overlay-main site-bg-white opacity-01"></div>
          <Container>
            <div className="wt-bnr-inr-entry">
              <div className="banner-title-outer">
                <div className="banner-title-name">
                  <h2 className="wt-title">Employee Candidate</h2>
                </div>
              </div>

              <div>
                <ul className="wt-breadcrumb breadcrumb-style-2">
                  <li>
                    <Link className="text-primary nav-link" to={"/employer"}>
                      Halaman Awal
                    </Link>
                  </li>
                  <li>Employee Candidate</li>
                </ul>
              </div>
            </div>
          </Container>
        </div>
        {!isLoading && isSuccess && <Swiper
          autoplay={{
            delay: 2500,
            disableOnInteraction: true
          }}
          spaceBetween={30}
          slidesPerView={3}
          breakpoints={{
            320: {
              width: 320,
              slidesPerView: 1
            },
            480: {
              width: 480,
              slidesPerView: 1
            },
            640: {
              width: 640,
              slidesPerView: 1
            },
            768: {
              width: 768,
              slidesPerView: 3
            }
          }}
          modules={[Autoplay]}
          className="py-3 px-2"
        >
          {data.map((candidate) => (
            <SwiperSlide key={candidate.id}>
              <Card onClick={() => goDetail(candidate.userId)}>
                <CardCandidate image={candidate.user.seekerProfile.photo ? constants.apiImageResourceURL + candidate.user.seekerProfile.photo : noPhoto} item={candidate} />
              </Card>
            </SwiperSlide>
          ))}
        </Swiper>}
      </div>
      <Footer navigations={footerData} />
    </div>
  )
}

export default Candidate

const CardCandidate = ({ image, item, onClick = () => { } }) => {
  const { width: widthScreen } = useWindowDimensions()
  const isLarge = widthScreen >= 1500
  const isMobile = widthScreen <= 768

  return (
    <div
      onClick={onClick}

      className={`bg-white radius-md p-1 card-candidate`}
    >
      <div className="">
        {item?.seekerProfile?.qualification && (
          <div
            style={{
              fontSize: 8,
              position: "absolute",
              bottom: 8,
              borderRadius: 4,
              left: 8,
              color: "#fff",
              background: "#92388E",
              padding: "2px 4px 2px 4px",
            }}
            className=""
          >
            {item?.seekerProfile?.qualification}
          </div>
        )}
        <img
          className="img-profile"
          style={{ borderRadius: 8, height: isLarge ? 205 : 163, width: "100%", objectFit: "contain" }}
          src={image}
          alt="Photo Candidates"
        />
      </div>
      <div className="flex item-start flex-col gap-y-2 mt-1">
        <h5 style={{ fontSize: 14, fontWeight: "bold" }} className="">{`${item.user.firstName} ${item.user.lastName}`}</h5>
        <div>
          <div className="flex gap-x-2">
            <img src={Tower} alt="tower" />
            <div style={{ fontSize: 12 }} className="text-gray-400">
              {item?.user?.seekerProfile?.SeekerQualification?.[0]?.qualification || "-"}
            </div>
          </div>
          <div className="flex gap-x-2">
            <img src={Location} alt="location" />
            <div style={{ fontSize: 12 }} className={`${isMobile ? "mt-1" : ""} text-gray-400`}>
              {item?.user?.seekerProfile?.province || "-"}
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="text-end text-sm text-pink-500 align-content-center cursor-pointer" >View Profile {'>'}</div>
      </div>
    </div>
  )
}
