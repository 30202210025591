import moment from "moment"
import { DefaultRoute } from "../router/routes"
import Avatar from "@src/assets/images/chat/avatar_chat.jpg"
import constants from "./constants"

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = (num) => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, "")

// ** Checks if the passed date is today
const isToday = (date) => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
  value,
  formatting = { month: "short", day: "numeric", year: "numeric" }
) => {
  if (!value) return value
  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: "short", day: "numeric" }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: "numeric", minute: "numeric" }
  }

  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem("userData")
export const getUserData = () => JSON.parse(localStorage.getItem("userData"))

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole === "admin") return DefaultRoute
  if (userRole === "client") return "/access-control"
  return "/login"
}

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: "#7367f01a", // for option hover bg-color
    primary: "#7367f0", // for selected option bg-color
    neutral10: "#7367f0", // for tags bg-color
    neutral20: "#ededed", // for input border-color
    neutral30: "#ededed", // for input hover border-color
  },
})

export const experienceDurationFormatting = ({
  yearStart,
  yearEnd,
  monthStart,
  monthEnd,
  isCurrentJob,
}) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]
  const start = monthNames[monthStart - 1]
  const end = monthNames[monthEnd - 1]
  const date = `${start} ${yearStart} - ${isCurrentJob ? "Sekarang" : end} ${
    isCurrentJob ? "" : yearEnd
  }`

  const year = yearEnd - yearStart > 0 ? `${yearEnd - yearStart} year` : ""
  const month = monthEnd - monthStart > 0 ? `${monthEnd - monthStart} month` : ""
  const duration = `${year} ${month}`

  return { date, duration }
}

export const renderFileSize = (size) => {
  if (Math.round(size / 100) / 10 > 1000) {
    return `${(Math.round(size / 100) / 10000).toFixed(1)} mb`
  } else {
    return `${(Math.round(size / 100) / 10).toFixed(1)} kb`
  }
}

export const cleanObjectField = (data, keys) => {
  for (const key of keys) {
    delete data[key]
  }
  return data
}

export function _delete(obj, prop) {
  if (obj[prop] && !obj[prop].length) delete obj[prop]
}

export const cleanObjectFieldWithNullValue = ({ data, includeEmptyString }) => {
  for (const key in data) {
    if (
      data[key] === null ||
      data[key] === undefined ||
      data[key] === "undefined" ||
      data[key] === "null"
    ) {
      delete data[key]
    } else if (data[key] === NaN) {
      delete data[key]
    } else if (includeEmptyString) {
      if (data[key] === "") {
        delete data[key]
      }
    }
  }
  return data
}
export const replaceObjectNullValueWithEmptyString = ({ data }) => {
  for (const key in data) {
    if (
      data[key] === null ||
      data[key] === undefined ||
      data[key] === "undefined" ||
      data[key] === "null"
    ) {
      data[key] = ""
    } else if (data[key] === NaN) {
      data[key] = ""
    }
  }
  return data
}

export const replaceObjectFieldWithNullValue = (data) => {
  for (const key in data) {
    if (
      data[key] === null ||
      data[key] === undefined ||
      data[key] === "undefined" ||
      data[key] === "null"
    ) {
      data[key] = ""
    }
    if (data[key] === NaN) {
      data[key] = 0
    }
  }
  return data
}

export const beetweenDate = (date) => {
  // Create a Date object for the target date
  const targetDate = new Date(date)

  // Get the current date
  const currentDate = new Date()

  // Calculate the difference in milliseconds
  const diffInMilliseconds = targetDate.getTime() - currentDate.getTime()

  // Convert the difference to days
  const diffInDays = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24))

  return parseInt(diffInDays)
}

export function isStringNumber(value) {
  return !isNaN(Number(value))
}

export const dayToDate = (day) => {
  const date = new Date()
  date.setDate(date.getDate() + day)
  return date.toISOString().slice(0, 10)
}

export function isDateExpired(dateString) {
  const expiryDate = moment(dateString, "YYYY-MM-DD")
  const today = moment()
  return expiryDate.isBefore(today)
}

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function formatPhoneNumber(phone) {
  // Remove non-digit characters
  phone = phone.replace(/\D/g, "")

  // Check if the number starts with '0' and replace it with '62'
  if (phone.startsWith("0")) {
    phone = `62${phone.slice(1)}`
  }

  // Return the formatted phone number
  return phone
}

export const getPhoto = (img) => {
  if (!img) return Avatar
  return `${constants.apiImageResourceURL}${img}`
}
