import { getPhoto } from "@src/utility/Utils"
import moment from "moment"
import { useMemo } from "react"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export default function Message({ message, self }) {
  console.log("🚀 ~ Message ~ message:", message)
  const dateTime = useMemo(() => {
    const today = moment().format("DDMMYY")
    const currentDate = moment(message?.createdAt).format("DDMMYY")

    return currentDate === today
      ? moment(message?.createdAt).format("HH:MM A")
      : moment(message?.createdAt).format("DD/MM/YY HH:MM A")
  }, [message])
  return (
    <li
      className={classNames(
        self !== message?.user?.email ? "justify-content-start" : "justify-content-end",
        "d-flex px-2"
      )}
    >
      <div className="item d-flex align-items-start">
        {self !== message?.user?.email && (
          <div className="flex-shrink-0">
            <img
              src={getPhoto(message?.user?.seekerProfile?.photo)}
              className="rounded-circle profile-chat-msg"
              alt="profil"
            />
          </div>
        )}

        <div
          className="chat-message rounded "
          style={{
            backgroundColor: self !== message?.user?.email ? "#615EF0" : "#F1F1F1",
          }}
        >
          <div className="d-flex align-items-center ">
            <pre
              className="block font-normal"
              style={{
                color: self !== message?.user?.email ? "#fff" : "#444444",
                backgroundColor: "transparent",
                textWrap: "wrap",
                overflow: "hidden",
              }}
            >
              {message?.message || "..."}
            </pre>
          </div>
          <span
            style={{
              fontSize: 8,
              textAlign: self !== message?.user?.email ? "left" : "right",
              color: self !== message?.user?.email ? "#f5f5f5" : "#a6a5a2",
            }}
          >
            {dateTime}
          </span>
        </div>

        {self === message?.user?.email && (
          <div className="flex-shrink-0">
            <img
              src={getPhoto(message?.user?.seekerProfile?.photo)}
              className="rounded-3 profile-chat-msg"
              alt="profil"
            />
          </div>
        )}
      </div>
    </li>
  )
}
