import { useEffect } from "react"

import NavigationBar from "@shared/NavigationBar"
import banner1 from '@src/assets/images/banner/1.jpg'
import pic1 from '@src/assets/images/jobs-company/pic1.jpg'
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom"

import { dashboard as navigations, footer as footerData, navbar as navbarData } from "@src/navigation/landing-employer"
import { Alert, Col, Container, Row, Spinner } from "reactstrap"

import Footer from "@shared/Footer"
import logo from '@src/assets/images/logo/logo.png'

import ModalAlert from "@shared/modals/ModalAlert"
import { useTranslation } from "react-i18next"
import { logout } from "../../../helpers/localstorage"
import { useGetMyCompanyMutation } from "../../../redux/company/company_service"
import { useUpdateEmployerProfileMutation } from "../../../redux/employer/profile"
import constants from "../../../utility/constants"
import Error from "@src/views/Error"
import { protectPage } from "@src/helpers/checkAuth"
// import i18n from "../../../configs/i18n"

function Header() {

  const { t } = useTranslation()

  return (
    <>
      <div className="wt-bnr-inr overlay-wraper bg-center" style={{ backgroundImage: `url(${banner1})` }}>
        <div className="overlay-main site-bg-white opacity-01"></div>
        <Container>
          <div className="wt-bnr-inr-entry">
            <div className="banner-title-outer">
              <div className="banner-title-name">
                <h2 className="wt-title">{t('empProfile')}</h2>
              </div>
            </div>

            <div>
              <ul className="wt-breadcrumb breadcrumb-style-2">
                <li><Link className="text-primary nav-link" to={'/employer'} >{t('home')}</Link></li>
                <li>{t('empProfile')}</li>
              </ul>
            </div>
          </div>
        </Container>
      </div>
    </>
  )
}

function Sidebar() {
  const [getCompany, { error, isError, data, isLoading }] = useGetMyCompanyMutation()
  const [updateProfile, updateProfileResult] = useUpdateEmployerProfileMutation()
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()
  const isActive = (link) => {
    return location.pathname === link ? 'active' : ''
  }
  const handleLogout = () => {
    logout()
    navigate('/login', {
      replace: true,
      state: { refresh: true }
    })
  }
  const updatePhoto = (e) => {
    const file = e.target.files[0]
    if (file.size > 1000000) {
      return toast.error('File size must be less than 1MB')
    }

    if (file) {
      const form = new FormData()
      form.append('imageLogo', file)
      updateProfile(form)
    }
  }

  useEffect(() => {
    getCompany()
  }, [])

  useEffect(() => {
    if (data) {
      localStorage.setItem('companyId', data.id)
    }
  }, [data])

  useEffect(() => {
    if (updateProfileResult.isSuccess) {
      getCompany()
    }
  }, [updateProfileResult])

  return (
    <>
      <div className="side-bar-st-1">
        {error ? (
          <Alert color="danger" className="px-2 py-1">
            <p>Error: {error?.data?.message}</p>
          </Alert>
        ) : isLoading ? (
          <p>...Loading</p>
        ) : data ? (
          <>
            <div className="twm-candidate-profile-pic">
              {updateProfileResult.isLoading ? (
                <Spinner size={"sm"} />
              ) : (
                <>
                  <img src={data.imageLogo ? `${constants.apiImageResourceURL}${data.imageLogo}` : pic1} alt="Profile Pic" />
                  <div className="upload-btn-wrapper">
                    <button className="site-button button-sm">{data.photo ? "Change photo" : "Upload photo"}</button>
                    <input type="file" name="imageLogo" accept=".pdf, .png, .jpg, .jpeg, .doc, .docx, .xls, .xlsx" onChange={updatePhoto} />
                  </div>
                </>
              )
              }
            </div>

            <div className="twm-mid-content text-center">
              <a href="candidate-detail.html" className="twm-job-title">
                <h5>{data.name}</h5>
              </a>
              <p>{data.JobIndustry.name}</p>
            </div>
          </>
        ) : null}

        <div className="twm-nav-list-1">
          <ul>
            {navigations.length > 0 ? navigations.map(val => {
              return (
                <li key={val.navLink} className={isActive(val.navLink)}>
                  {String(val.navLink).includes('logout') ? (
                    <a href="#" type="button" onClick={handleLogout}>{<div className="me-1">{val.icon}</div>} {t('Logout')}</a>
                  ) : (
                    <Link to={val.navLink}>{<div className="me-1">{val.icon}</div>} {t(val.title)}</Link>
                  )}
                </li>
              )
            }) : null}
          </ul>
        </div>
      </div>
      <ModalAlert
        isOpen={isError}
        title="Failed"
        onClick={handleLogout}
        closeText="Ok">
        {error?.data?.message}, login needed
      </ModalAlert>
    </>
  )
}

function Dashboard() {

  if (!protectPage({ currentPath: 'employer' })) {
    return <Error />
  }
  return (
    <>
      <div className="page-content">
        <NavigationBar isNew={true} bgColor='primary' navigations={navbarData} page="employer" />
        <Header />
        <div className="section-full p-t120 p-b90 site-bg-white">
          <Container fluid>
            <Row sm={1}>
              <Col lg={3} className="rightSidebar m-b30">
                <Sidebar />
              </Col>

              <Col lg={9} className="m-b30">
                <div className="twm-right-section-panel site-bg-gray">
                  <Outlet />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer logo={logo} navigations={footerData()} />
      </div>
    </>
  )
}

export default Dashboard
