import { createSlice } from "@reduxjs/toolkit"


const initialState = {
    value: {
        isOpen: false,
        activeChat: null
    }
}

export const chatAction = createSlice({
    name: 'chat_action',
    initialState,
    reducers: {
        showChat: (state, action) => {
            state.value = {
                isOpen: true,
                activeChat: action.payload
            }
        },
        hideChat: (state) => {
            state.value = {
                isOpen: false,
                activeChat: state.value.activeChat
            }
        },
        onActiveChat: (state, action) => {
            state.value = {
                ...state.value,
                activeChat: action.payload
            }
        }
    }
})

export const {
    showChat,
    hideChat,
    onActiveChat
} = chatAction.actions

export default chatAction.reducer
