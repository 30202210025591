import Head from '@src/components/head'
import NavigationBar from '@src/views/shared/NavigationBar'
import { footer as footerData, navbar as navigations } from "@src/navigation/landing-employer"
import './index.scss'
import React from 'react'
import Banner from './components/Banner'
import StatsV2 from './components/StatsV2'
import Streamline from './components/Streamline'
import Features from './components/Features'
import Questions from './components/Questions'
import Footer from '@src/views/shared/Footer'
import Candidates from './components/Candidates'

const LandingEmployerV2 = () => {
    return (
        <div className='page-wrapper bg-gray-200 section-background'>
            <Head title="Employer - Mahajob" name="Maha-job" description="Landing Employer Mahajob" />
            <NavigationBar page={"employer"} navigations={navigations} isNew={true} />
            <Banner />
            <StatsV2 />
            <Streamline />
            <Candidates />
            <Features />
            <Questions />
            <Footer navigations={footerData()} />
        </div>
    )
}

export default LandingEmployerV2