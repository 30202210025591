import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { authHeader, baseUrlConfig } from "../../helpers/http"

const baseurl = "job/"

export const jobPostApi = createApi({
  reducerPath: "job/post",
  baseQuery: fetchBaseQuery(baseUrlConfig),
  tagTypes: ["JobPost"],
  endpoints: (builder) => ({
    getJobPost: builder.query({
      query: (params) => {
        if (!params.all) params.limit = 10
        return {
          url: `${baseurl}`,
          params,
          method: "get",
        }
      },
      providesTags: ["GetJobPost"],
      transformResponse: (response) => response.results,
    }),
    getJobPostIsPriority: builder.mutation({
      query: (params) => ({
        url: `${baseurl}`,
        params,
        method: "get",
      }),
      providesTags: ["GetJobPostIsPriority"],
      transformResponse: (response) => {
        // console.log("LENGTH : ", response.results.length)
        return response.results.filter((data) => data.isPriority)
      },
    }),
    getJobPostIsNotPriority: builder.mutation({
      query: (params) => ({
        url: `${baseurl}`,
        params,
        method: "get",
      }),
      providesTags: ["GetJobPostIsNotPriority"],
      transformResponse: (response) => response.results.filter((data) => !data.isPriority),
    }),
    getJobPostPagination: builder.query({
      query: (params) => ({
        url: `${baseurl}`,
        params,
        method: "get",
      }),
      providesTags: ["GetJobPostWithPagination"],
    }),
    getJobPostBySlug: builder.mutation({
      query: (slug) => ({
        url: `${baseurl}/${slug}`,
        method: "get",
        params: {
          jobIndustry: true,
        },
      }),
      providesTags: ["GetJobPostDetail"],
      transformResponse: (response) => response.results,
    }),
    getJobPostCount: builder.query({
      query: () => ({
        url: `${baseurl}/count`,
        method: "GET",
      }),
      providesTags: ["JobPost"],
      transformResponse: (response) => response.results,
    }),
    postJobPost: builder.mutation({
      query: (body) => {
        const data = new FormData()
        for (const key in body) {
          data.append(key, body[key])
        }
        return {
          url: `${baseurl}`,
          method: "post",
          headers: authHeader(),
          body: data,
        }
      },
      providesTags: ["PostJobPost"],
    }),
    updateJobPost: builder.mutation({
      query(data) {
        const { id, ...bodyRaw } = data
        const body = new FormData()
        for (const key in bodyRaw) {
          console.log(`${key} : ${bodyRaw[key]}`)
          body.append(key, bodyRaw[key])
        }
        console.log(id)
        return {
          url: `${baseurl}/${id}`,
          method: "put",
          headers: authHeader(),
          body,
        }
      },
      providesTags: ["UpdateJobPost"],
      transformResponse: (response) => response.results,
    }),
    deleteJobPost: builder.mutation({
      query: ({ id }) => ({
        url: `${baseurl}${id}`,
        method: "delete",
        headers: authHeader(),
      }),
      providesTags: ["DeleteJobPost"],
      transformResponse: (response) => response.results,
    }),

    // TODO: for employer
    getJobPostEmployer: builder.mutation({
      query: (params) => {
        if (!params.all) params.limit = 10
        return {
          url: `employer/jobs`,
          params,
          method: "get",
          headers: authHeader(),
        }
      },
      providesTags: ["GetJobPost"],
      transformResponse: (response) => response.results,
    }),
  }),
})

export const {
  useGetJobPostQuery,
  useGetJobPostIsPriorityMutation,
  useGetJobPostIsNotPriorityMutation,
  useGetJobPostBySlugMutation,
  useGetJobPostCountQuery,
  usePostJobPostMutation,
  useUpdateJobPostMutation,
  useGetJobPostPaginationQuery,
  useDeleteJobPostMutation,
  useGetJobPostEmployerMutation,
} = jobPostApi
